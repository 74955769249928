<template>
  <div>
    <HeaderBasic>
      <h2 class="page-title">Tenants</h2>
      <router-link to="/" :exact="true">Home</router-link>
      <span> / </span>
      <span class="current">Tenants</span>
    </HeaderBasic>
    <!-- Start About Section  -->
    <section class="about section custom-about2">
      <div class="container">
        <div class="row">
          <div class="col-md-6 wow animate__animated animate__fadeInLeft">
            <h2 class="section-title">Tenants</h2>
            <p>
              At Asamai Properties, our aim is to find you the perfect property
              to suit your requirements with the minimum of hassle. We offer all
              these with very low agency fees as stated below.
            </p>
            <h3>Finding a property</h3>
            <p>
              The first step is to contact us to provide brief details about
              yourself and about the accommodation you require. We then search
              our database for suitable properties for you to view. If not
              urgent, we log your details so that we can contact you as suitable
              properties become available.
            </p>
            <p>
              It is wise to start looking about a month before you wish to move,
              as the nicer properties are viewed and reserved quickly. Our
              tenancies are granted for a minimum term of 6 months, which may be
              renewed if all parties are in agreement.
            </p>
            <h3>Viewings</h3>
            <p>
              At Asamai Properties, viewings will be arranged at times to suit
              you, including evenings and weekends (subject to availability),
              and you will invariably be accompanied by a member of staff, who
              can give advice and answer any questions you may have.
            </p>
            <h3>Reserving the property</h3>
            <p>
              Once you have decided on a property, you will need to reserve it
              by paying a 'holding deposit' (aka 'reservation deposit'), usually
              equivalent to two weeks' rent. Assuming that the tenancy goes
              ahead, this will be deducted from the monies due before you move
              in. If the landlord declines to grant the tenancy, it will be
              refunded. However, the deposit will be forfeit if you decide not
              to proceed for any reason, or if you fail to provide complete and
              accurate information in your application.
            </p>
          </div>
          <div class="col-md-6 wow animate__animated animate__fadeInRight">
            <h3>References</h3>
            <p>
              At Asamai Properties, within the time frame, we will always
              require a minimum set of references that are satisfactory just to
              protect our customers all the way through. Mostly these are
              references are from your employer (or college), a previous or
              current landlord, and a credit reference report.
            </p>
            <p>
              We may also require a personal reference, and sometimes a
              guarantor. We may use the services of an independent referencing
              company to obtain and evaluate these references.
            </p>
            <h3>Rents</h3>
            <p>
              At Asamai Properties, rents are normally quoted calendar monthly,
              and payable monthly in advance. The tenant is usually also
              responsible for Council Tax, Water Rates, Gas, Electricity and
              Telephone costs.
            </p>
            <p>
              All rents are payable by bank standing order to our company bank
              account or your landlord's bank account.
            </p>
            <h3>Deposit</h3>
            <p>
              At Asamai Properties, we always ensure that all our customers are
              protect legally. As such, a security deposit of a minimum of one
              month's rental will normally be required.
            </p>
            <p>
              This deposit will be treated in accordance with the Tenancy
              Deposit Protection regulations, and you will be provided with the
              appropriate prescribed information and receive details regarding
              the scheme used.
            </p>
            <p>
              The deposit is held to cover damage, breakages, and any other
              liabilities under the terms of your tenancy agreement.
            </p>
            <p>
              Please note that under no circumstances can the deposit be used by
              the tenant to cover rent.
            </p>
            <h3>Fees</h3>
            <p>
              We charge a modest fee to cover administration and referencing
              costs. In rare cases the fee can vary, but usually we charge
              £150.00
            </p>
          </div>
        </div>
      </div>
    </section>
    <!-- End About Section  -->
  </div>
</template>
<script>
import { WOW } from "wowjs";
export default {
  components: {
    HeaderBasic: () => import("./HeaderBasic")
  },
  mounted() {
    var wow = new WOW({
      live: false
    });
    wow.init();
  }
};
</script>
